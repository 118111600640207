
import { computed, defineComponent, inject } from 'vue'
import WhatBoxGroup from '@/components/WhatBoxGroup.vue'
import WhatLoading from '@/components/WhatLoading.vue'
import WhatLoadingMobile from '@/components/WhatLoadingMobile.vue'

export default defineComponent({
    components: {
        WhatBoxGroup,
        WhatLoading,
        WhatLoadingMobile,
    },
    setup() {
        const whatStore: any = inject('whatStore')
        return {
            loading: computed(() => whatStore.state.whatState.loading),
        }
    },
})
