<template>
    <div id="whatLoadingMobileContainer">
        <div id="header">
            <div class="item"></div>
            <div class="item"></div>
            <div class="item"></div>
        </div>
        <div id="content">
            <div class="post">
                <div class="image item"></div>
                <div class="postResume">
                    <div class="item"></div>
                    <div class="subtittle">
                        <div class="item circle"></div>
                        <div class="item"></div>
                    </div>
                    <div class="item"></div>
                    <div class="item"></div>
                    <div class="item"></div>
                    <div class="item"></div>
                </div>
            </div>
            <div class="post">
                <div class="image item"></div>
                <div class="postResume">
                    <div class="item"></div>
                    <div class="subtittle">
                        <div class="item circle"></div>
                        <div class="item"></div>
                    </div>
                    <div class="item"></div>
                    <div class="item"></div>
                    <div class="item"></div>
                    <div class="item"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
* {
    box-sizing: border-box;
}
.circle {
    border-radius: 50%;
}
.item {
    background: #e6e6e6;
    position: relative;
    border-radius: 10px;
}
.item:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-100px);
    background: linear-gradient(
        90deg,
        transparent,
        rgba(255, 255, 255, 0.4),
        transparent
    );
    animation: loading 0.8s infinite;
}
@keyframes loading {
    100% {
        transform: translateX(100%);
    }
}
#whatLoadingMobileContainer {
    padding: 20px;
    #header {
        display: flex;
        flex-direction: column;
        > .item {
            &:nth-child(1) {
                height: 30px;
                width: 20%;
                margin-bottom: 20px;
            }
            &:nth-child(2) {
                height: 10px;
                width: 40%;
                margin-bottom: 20px;
            }
            &:nth-child(3) {
                height: 5px;
                width: 100%;
                margin-bottom: 20px;
            }
        }
    }
    #content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .post {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            > .image {
                width: 100%;
                height: 150px;
                border-radius: 0px;
            }
            > .postResume {
                width: 100%;
                padding: 20px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                > .item {
                    width: 100%;
                    height: 20px;
                    margin-bottom: 10px;
                    &:last-child {
                        width: 30%;
                        margin-top: 30px;
                        margin-left: 40px;
                        height: 10px;
                    }
                }
                > .subtittle {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-bottom: 10px;
                    > .item {
                        &:nth-child(1) {
                            width: 20px;
                            height: 20px;
                            margin-right: 10px;
                        }
                        &:nth-child(2) {
                            width: 30%;
                            height: 10px;
                        }
                    }
                }
            }
        }
    }
}
</style>