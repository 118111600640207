import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c306a780"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "whatBoxGroupContainer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WhatBox = _resolveComponent("WhatBox")!
  const _component_ion_infinite_scroll_content = _resolveComponent("ion-infinite-scroll-content")!
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.whatState.posts, (post) => {
      return (_openBlock(), _createBlock(_component_WhatBox, {
        id: "whatBoxContainer",
        key: post.identifier,
        onClick: ($event: any) => (_ctx.openWhatPost(post.identifier)),
        img: 
                post.content.better_featured_image.media_details.sizes[
                    'et-pb-post-main-image'
                ].source_url
            ,
        title: post.content.title.rendered,
        commentsNumber: _ctx.whatState.commentsCount[post.identifier],
        previewText: _ctx.getTextFromHtml(post.content.content.rendered),
        lastCommentOwn: "Prueba"
      }, null, 8, ["onClick", "img", "title", "commentsNumber", "previewText"]))
    }), 128)),
    _createVNode(_component_ion_infinite_scroll, {
      onIonInfinite: _cache[0] || (_cache[0] = ($event: any) => (_ctx.loadData($event))),
      threshold: "100px",
      id: "infinite-scroll",
      disabled: _ctx.isInfiniteScrollDisabled
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_infinite_scroll_content, {
          "loading-spinner": "bubbles",
          "loading-text": _ctx.$t('tester_loading_posts')
        }, null, 8, ["loading-text"])
      ]),
      _: 1
    }, 8, ["disabled"])
  ]))
}