
import { computed, defineComponent, inject, onBeforeMount, ref } from 'vue'
import { IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/vue'
import { useRouter } from 'vue-router'
import WhatBox from '@/components/WhatBox.vue'

export default defineComponent({
    components: {
        WhatBox,
        IonInfiniteScroll,
        IonInfiniteScrollContent,
    },
    setup() {
        const whatStore: any = inject('whatStore')
        const isInfiniteScrollDisabled = ref(false)
        const router: any = useRouter()
        const infinityPage = ref(0)

        const loadData = async (e: any) => {
            infinityPage.value++
            await whatStore.getWhatPosts(infinityPage.value)
            await whatStore.getWhatPostsCommentsCount({
                postIds: whatStore.state.whatState.posts.map(
                    (o: any) => o.identifier
                ),
            })
            e.target.complete()
            if (infinityPage.value == whatStore.state.whatState.maxPages - 1) {
                isInfiniteScrollDisabled.value = true
            }
        }
        const openWhatPost = (id: number) => {
            router.push({ name: 'what.post', params: { id } })
        }
        const getTextFromHtml = (html: any) => {
            var div = document.createElement('div')
            div.innerHTML = html
            return (
                div.querySelector('p')?.textContent ||
                div.querySelector('p')?.innerText ||
                ''
            )
        }
        onBeforeMount(async () => {
            await whatStore.getWhatPosts(0)
            await whatStore.getWhatPostsCommentsCount({
                postIds: whatStore.state.whatState.posts.map(
                    (o: any) => o.identifier
                ),
            })
        })
        return {
            whatState: computed(() => whatStore.state.whatState),
            loadData,
            isInfiniteScrollDisabled,
            openWhatPost,
            getTextFromHtml,
        }
    },
})
